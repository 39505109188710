<template>
    <div>
        <material-card class="mx-4"
                       :title="get_title"
                       text="Data for wellstar sales staff">

            <template v-if="!!order" :slot="'header_actions'">

                <v-btn small fab class="ml-2"
                       v-tooltip="'Filter columns'"
                       @click="show_filter = !show_filter">
                    <v-icon>mdi-filter</v-icon>
                </v-btn>

                <v-btn small fab class="ml-2"
                       v-tooltip="'Download' + (order.stage <= 4) ? 'Quote' : 'PI'"
                       @click="download_generated_pi_or_quote($event, order)"
                       :loading="order.is_loading_pi" :disabled="order.is_loading_pi">
                    <v-icon>mdi-download</v-icon>
                </v-btn>

                <v-btn small fab class="ml-2"
                       v-tooltip="'Add product'"
                       @click="product_dialog = true">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-btn small fab class="ml-2"
                       v-tooltip="'Reload order information'"
                       @click="load_order">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn small fab class="ml-2"
                       v-tooltip="'Open chat for order'"
                       @click="chat_opened = !chat_opened">
                    <v-icon>mdi-message</v-icon>
                </v-btn>
                <v-btn small fab
                       class="outline_btn stage_button ml-2"
                       :class="'stage_btn-' + get_stage">
                    {{ get_stage }}
                </v-btn>
            </template>

            <v-container>
                <v-row v-if="!order && !!is_loading">
                    <v-progress-circular
                            :size="50"
                            color="primary"
                            indeterminate
                    />
                </v-row>

                <template v-if="!!order && !is_loading">

                    <v-row>
                        <h3 class="my-0">Factories</h3>
                        <v-spacer/>
                        <v-btn x-small fab color="primary ml-2"
                               :disabled="factories_selected.length === 0"
                               @click="clone_orders()"
                               v-tooltip="'Copy / Clone Selected To New PI'">
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>

                        <v-btn x-small fab color="primary ml-2"
                               :disabled="factories_selected.length === 0"
                               @click="move_orderlines()"
                               v-tooltip="'Move products from selected to new PI'">
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                    </v-row>

                    <v-divider class="mt-1"/>

                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th class="text-center"><v-icon>mdi-checkbox-marked-outline</v-icon></th>
                                <th class="text-center">#</th>
                                <th class="text-center">Name</th>
                                <th class="text-center">No of Products</th>
                                <th class="text-center">Shipping Port</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(factory, key) in order.factories" :key="key">
                                <td class="text-center">
                                    <v-switch @change="add_remove_selected_factory(factory)"
                                              class="mx-2" hide-details>
                                    </v-switch>
                                </td>
                                <td>{{ factory.id }}</td>
                                <td>{{ factory.name }}</td>
                                <td class="text-center"> {{ order.orderlines.filter(o => o.product.factory.id === factory.id).length }}</td>
                                <td class="text-center">{{ factory.shipping_port }}</td>
                                <td class="text-center">
                                    <v-btn fab text small
                                           color="blue-grey darken-4"
                                           @click="open_factory_mail(factory)">
                                        <v-icon> mdi-email</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <v-row class="title" v-if="show_filter">Filter Titles</v-row>

                    <v-row v-if="show_filter">
                        <template v-for="header in headers">
                            <v-col cols="12" sm="6" md="4" >
                                <v-switch v-model="header.active"
                                          @change="update_orders()"
                                          class="mx-2" :label="header.title"/>
                            </v-col>
                        </template>
                    </v-row>


                    <v-row class="title mt-1">Items</v-row>

                    <v-row>

                        <v-simple-table dense class="invoice_items_table" style="max-width: 100%;"
                                        :fixed-header="true"
                                        :height="800"
                        >
                            <thead>
                                <tr>
                                    <th v-for="header in headers" v-show="header.active"
                                        v-if="check_access(header.table, allowed_access_to_final)">
                                        {{ header.title }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(line, key) of order.orderlines" :key="key">

                                <td v-for="header in headers" v-show="header.active">
                                    <div v-if="header.table === 'actions'">
                                        <v-btn color="primary" fab small
                                               @click="remove_ol(line)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>

                                    <div v-if="header.table === 'item_number'">
                                        <h2 class="title text-center">
                                            {{line.product.item_number}}
                                        </h2>
                                    </div>

                                    <div v-if="header.table === 'difference'">
                                        <div class="form-group">
                                            <div class="alert pa-5 white--text"
                                                 v-bind:class="{ 'info': calc_line_department_increase_success(line), 'success': !calc_line_department_increase_success(line)}">
                                                <br>
                                                Department Profit Set TO: {{ line.department_percentage }}%
                                                <br>
                                                Actual Increase Set To: {{ calc_line_department_increase(line) }}%
                                                <div v-show="calc_line_department_increase_success(line)">
                                                    <h3 class="subtitle-1">
                                                        Possible Error {{ calc_line_department_increase(line) }} %
                                                    </h3>
                                                    <v-btn block color="primary"
                                                           @click="rebase_prices(line, line.department_percentage)">
                                                        Rebase
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                    </div>

                                    <div v-if="header.table === 'image'" style="max-width:150px;">

                                        <v-img
                                                v-if="line.product.album && line.product.album.files.length"
                                                class="white--text"
                                                max-width="150px"
                                                :src="line.product.album.files[0].url"
                                        >
                                        </v-img>
                                    </div>

                                    <div v-if="header.table === 'factory'" style="max-width:150px;">
                                        <span class="text-center">{{ get_factory_name(line.product.factory) }}</span>
                                    </div>

                                    <div v-if="header.table === 'name'" style="max-width:150px;">
                                        <span @click="edit_product(line.product)">
                                            {{ line.product.name }}
                                        </span>
                                    </div>

                                    <div v-if="header.table === 'qty'"
                                         style="max-width:100px; min-width:100px;"
                                    >
                                        <v-text-field
                                                style="max-width: 90px"
                                                label=""
                                                v-model="line.qty"
                                                type="number"
                                        ></v-text-field>
                                    </div>

                                    <div v-if="header.table === 'cust_fob' && allowed_access_to_final"
                                         style="max-width:80px; min-width:80px;">
                                        <v-text-field
                                                v-model="line.organisation_fob_price"
                                                label=""
                                                type="number"
                                        ></v-text-field>
                                    </div>

                                    <div v-if="header.table === 'cust_target' && allowed_access_to_final"
                                         style="max-width:80px; min-width:80px;">
                                        {{ calc_customer_target_fob(line) | currency }}
                                    </div>

                                    <div v-if="header.table === 'cust_total' && allowed_access_to_final">
                                        {{ line.organisation_fob_price * line.qty | currency }}
                                    </div>

                                    <div v-if="header.table === 'fact_fob'"
                                         style="max-width:80px; min-width:80px;">
                                        <v-text-field v-model="line.factory_fob_price"
                                                      label=""
                                                      type="number"
                                        />
                                    </div>

                                    <div v-if="header.table === 'fact_target'"
                                         style="max-width:80px; min-width:80px;">
                                        <span>{{ calc_factory_target_fob(line) }}</span>
                                    </div>

                                    <div v-if="header.table === 'fact_total'">
                                        {{ line.factory_fob_price * line.qty | currency }}
                                    </div>

                                    <div v-if="header.table === 'sizes'"
                                         style="max-width:350px; min-width:350px;" >
                                        <div>
                                            <strong class="title">
                                                Item Size
                                            </strong> <br>
                                            <table style="min-width:390px!important;">
                                                <tr>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.product.item_size_width"
                                                                type="number"
                                                                label="Width"
                                                        />
                                                    </th>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.product.item_size_height"
                                                                type="number"
                                                                label="Height"
                                                        />
                                                    </th>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.product.item_size_depth"
                                                                type="number"
                                                                label="Depth"
                                                        />
                                                    </th>
                                                </tr>
                                            </table>
                                        </div>
                                        <div>
                                            <strong class="title">
                                                Carton Size
                                            </strong> <br>
                                            <table style="min-width:390px!important;">
                                                <tr>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.carton_measures_width"
                                                                type="number"
                                                                label="Width"
                                                        />
                                                    </th>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.carton_measures_height"
                                                                type="number"
                                                                label="Height"
                                                        />
                                                    </th>
                                                    <th>
                                                        <v-text-field
                                                                style="max-width: 50px"
                                                                v-model="line.carton_measures_depth"
                                                                type="number"
                                                                label="Depth"
                                                        />
                                                    </th>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-if="header.table === 'item_cbm'"
                                         style="max-width:120px; min-width:120px;">
                                        {{ calc_item_cbm(line) }}
                                    </div>

                                    <div v-if="header.table === 'carton_cbm'"
                                         style="max-width:120px; min-width:120px;">
                                        {{ calc_carton_cbm(line) }}
                                    </div>

                                    <div v-if="header.table === 'total_item_cbm'"
                                         style="max-width:180px; min-width:120px;">
                                        {{ calc_item_ttl(line) }}
                                    </div>

                                    <div v-if="header.table === 'total_ctn_cbm'"
                                         style="max-width:180px; min-width:120px;">
                                        {{ set_to_fixed(calc_carton_ttl(line), 4) }}
                                    </div>

                                    <div v-if="header.table === 'total_mster_ctn'"
                                         style="max-width:120px; min-width:120px;">
                                        {{ set_to_fixed(calc_total_master_ctn(line), 4) }}
                                    </div>

                                    <div v-if="header.table === 'remarks'"
                                         style="max-width:320px; min-width:120px;">
                                        <v-textarea
                                                label=""
                                                v-model="line.remarks"
                                                class="">
                                        </v-textarea>
                                    </div>

                                    <div v-if="header.table === 'pcs_per_mst'">
                                        <v-text-field
                                                v-model="line.pieces_per_master_carton"
                                                type="number"
                                                label=""
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                        </v-simple-table>

                    </v-row>

                    <v-row class="title mt-8">Additional Information</v-row>

                    <v-row>
                        <v-simple-table dense>
                            <thead>
                                <tr>
                                    <th style="width:150px;" class="text-left">Total CTN</th>
                                    <th style="width:130px;"> {{ total_ctn }}</th>
                                </tr>

                                <tr v-if="allowed_access_to_final">
                                    <th class="text-left">Additional Customer Costs</th>
                                    <th>
                                        <v-text-field
                                                v-model="order.organisation_additional_costs"
                                                label=""
                                                type="number"
                                        />
                                    </th>
                                    <th>
                                        <v-textarea
                                                v-show="order.organisation_additional_costs > 0"
                                                label="Reason"
                                                :auto-grow="true"
                                                :flat="true"
                                                :single-line="true"
                                                v-model="order.org_additional_cost_reason"
                                        />
                                    </th>
                                </tr>

                                <tr>
                                    <th class="text-left">
                                        Additional Factory Costs
                                    </th>
                                    <th>
                                        <v-text-field
                                                v-model="order.factory_additional_costs"
                                                label=""
                                                type="number"
                                        />
                                    </th>
                                    <th>
                                        <v-textarea
                                                v-show="order.factory_additional_costs > 0"
                                                label="Reason"
                                                :auto-grow="true"
                                                :flat="true"
                                                :single-line="true"
                                                v-model="order.fact_additional_cost_reason"
                                        />
                                    </th>
                                </tr>

                                <tr>
                                    <th class="text-left">
                                        Factory Currency
                                    </th>
                                    <th>
                                        <v-select
                                                v-model="order.currency_used"
                                                :items="currencies"
                                                label=""
                                        />
                                    </th>
                                </tr>

                                <tr v-if="allowed_access_to_final">
                                    <th class="text-left">
                                        <v-switch
                                                v-model="order.has_rebate"
                                                :label="'Has Rebate'"
                                        />
                                    </th>
                                    <th>
                                        <v-text-field
                                                v-show="order.has_rebate"
                                                v-model="order.rebate"
                                                label="Rebate %"
                                                type="number"
                                        />
                                    </th>
                                </tr>

                                <tr>
                                    <th class="text-left"> Total Item CBM</th>
                                    <th> {{  set_to_fixed(total_item_cbm, 2) }}</th>
                                </tr>

                                <tr>
                                    <th class="text-left"> Total Carton CBM</th>
                                    <th>{{ set_to_fixed(total_carton_cbm, 2) }}</th>
                                </tr>

                                <tr>
                                    <th>Factory Total</th>
                                    <th>{{ factory_total | currency }}</th>
                                    <th> ¥{{ set_to_fixed((factory_total * order.exchange_rate), 2) }}</th>
                                </tr>

                                <tr>
                                    <th class="text-left"> Total</th>
                                    <th> {{ client_total | currency }}</th>
                                </tr>

                                </thead>
                        </v-simple-table>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.pi_number"
                                    :counter="100"
                                    label="PI Number"
                                    :disabled="true"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.po_number"
                                    :counter="100"
                                    label="PO Number"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-textarea
                                    name="input-7-1"
                                    label="General remarks"
                                    v-model="order.remarks"
                            />
                        </v-col>
                    </v-row>



                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-select
                                    v-model="order.partner"
                                    :items="partners"
                                    item-value="id"
                                    item-text="name"
                                    label="Partner"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.partner_profit_percent"
                                    type="number"
                                    label="Partner Profit %"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-select
                                    v-model="order.organisation"
                                    :items="customers"
                                    item-value="id"
                                    item-text="name"
                                    label="Customer"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <template v-if="customer">
                                <v-select
                                        v-model="order.department"
                                        :items="customer.departments"
                                        item-value="id"
                                        item-text="name"
                                        label="Department"
                                />
                            </template>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-menu v-model="shipping_start"
                                    :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            style="padding-top:45px;"
                                            v-model="order.shipping_start_date"
                                            label="Start Date"
                                            readonly
                                            v-on="on"
                                    />
                                </template>
                                <v-date-picker v-model="order.shipping_start_date"
                                               @input="shipping_start = false"/>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0">
                            <v-menu v-model="shipping_end"
                                    :close-on-content-click="false">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            style="padding-top:45px;"
                                            v-model="order.shipping_end_date"
                                            label="End Date"
                                            readonly
                                            v-on="on"
                                    />
                                </template>
                                <v-date-picker v-model="order.shipping_end_date"
                                               @input="shipping_end = false"
                                />
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.shipping_port"
                                    :counter="100"
                                    label="Shipping Port"

                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.value_20_gp"
                                    type="number"
                                    label="20 GP Amount"

                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.value_40_gp"
                                    type="number"
                                    label="40 GP Amount"

                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field
                                    v-model="order.value_40_hq"
                                    type="number"
                                    label="40 HQ Amount"

                            />
                        </v-col>
                    </v-row>

                    <v-divider/>

                    <v-btn block color="primary" class="mr-5"
                           @click="save_order()">
                        Save
                    </v-btn>

                </template>
            </v-container>

        </material-card>

        <div class="order-chat-wrapper" v-if="chat_opened">
            <w-order-chat :selected_invoice="order" @close_chat="chat_opened = false"/>
        </div>

        <v-dialog v-model="product_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="product_dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add Product's To Order</v-toolbar-title>
                    <v-spacer/>
                    <v-toolbar-items>
                        <v-btn dark text @click="product_dialog = false">Cancel</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                        v-model="search.item_number"
                                        :counter="200"
                                        label="Search For Item Number"
                                />
                            </v-col>
                            <v-col cols="12" sm="12" md="1" lg="1">
                                <v-btn color="primary" @click="search_products">
                                    <v-icon>
                                        mdi-magnify
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-container>
                            <v-row>
                                <template v-for="product in products">
                                    <v-col cols="3" >
                                        <v-card @click="set_product_sel(product)" class="search_product"
                                                v-bind:class="{'active': product.active === true}">
                                            <v-img v-if="product.album && product.album.files.length"
                                                   class="white--text" height="200px"
                                                   :src="product.album.files[0].url"
                                            />
                                            <v-card-title>
                                                <v-row>{{ product.name }}</v-row>
                                                <v-row>{{ product.erp_item_number }}</v-row>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-container>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn
                            color="blue-grey darken-4"
                            block
                            outlined
                            @click="add_products_to_order()"
                    >
                        Add To Order
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="edit_product_dialog" width="600">
            <v-card>
                <v-card-title>
                    Editing Product {{ product.item_number }} name
                </v-card-title>
                <v-card-text>
                    <v-text-field
                            v-model="product.name"
                            :counter="200"
                            label="Name"
                    />
                    <v-textarea
                            label="Description"
                            v-model="product.description"
                            class=""/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                          color="blue-grey darken-4"
                          @click="edit_product_dialog = false"
                        >
                        Cancel
                        </v-btn>
                    <v-btn
                          color="blue-grey darken-4"
                          @click="save_product(product)"
                        >
                          Save
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import moment from 'moment'
    import SendFactoryEmail from "@/components/w/SendFactoryEmail";
    import {download_files_digested, remove_slash_and_backslash, zip_generate_options} from "@/plugins/order_all_file_zip";
    import {gen_order_pi, gen_order_quote, gen_breakdown, gen_po} from '@/plugins/excel_pi_po_generator';

    import { calc_item_cbm, calc_carton_cbm, calc_item_ttl, calc_carton_ttl,
        calc_total_master_ctn, calc_factory_target_fob, calc_customer_target_fob, calc_line_department_increase,
        calc_line_department_increase_success, customer_target_price_percentage_difference, formatDateForPickers}
        from "@/plugins/wellstar_functions"

    export default {
        components: { SendFactoryEmail },
        data() {
            return {
                is_loading: true,
                chat_opened: false,
                show_filter: false,

                order_old: {},
                order: {is_loading:false},
                customers: [],
                customer: {
                    departments:[]
                },
                currencies: ['$', '¥', '€', '£'],
                partners: [],
                shipping_start: false,
                shipping_end: false,

                factories_selected: [],

                can_move: false,
                product_dialog: false,
                search: {
                    item_number: ''
                },
                products: [],
                selected_products: [],
                headers: [
                    {
                        title: 'Actions',
                        table: 'actions',
                        active: true,
                        align: 'align-center'
                    },
                    {
                        title: 'Item Number',
                        table: 'item_number',
                        active: true,
                        align: 'align-center'
                    },

                    {
                        title: 'Difference',
                        table: 'difference',
                        active: true,
                        align: 'align-center'
                    },

                    {
                        title: 'Image',
                        table: 'image',
                        active: true
                    },
                    {
                        title: 'Factory',
                        table: 'factory',
                        active: true
                    },

                    {
                        title: 'Name',
                        table: 'name',
                        active: true
                    },
                    {
                        title: 'Pieces Per Mst CTN',
                        table: 'pcs_per_mst',
                        active: true
                    },
                    {
                        title: 'QTY',
                        table: 'qty',
                        active: true
                    },
                    {
                        title: 'Cust FOB',
                        table: 'cust_fob',
                        active: true
                    },
                    {
                        title: 'Cust Target',
                        table: 'cust_target',
                        active: true
                    },
                    {
                        title: 'Cust Total',
                        table: 'cust_total',
                        active: true
                    },
                    {
                        title: 'Fact FOB',
                        table: 'fact_fob',
                        active: true
                    },
                    {
                        title: 'Fact Target',
                        table: 'fact_target',
                        active: true
                    },
                    {
                        title: 'Fact Total',
                        table: 'fact_total',
                        active: true
                    },
                    {
                        title: 'Sizes',
                        table: 'sizes',
                        active: false
                    },
                    {
                        title: 'Item CBM',
                        table: 'item_cbm',
                        active: false
                    },
                    {
                        title: 'Carton CBM',
                        table: 'carton_cbm',
                        active: false
                    },
                    {
                        title: 'TTL Master CTN',
                        table: 'total_mster_ctn',
                        active: false
                    },
                    {
                        title: 'TTL Item CBM',
                        table: 'total_item_cbm',
                        active: false
                    },
                    {
                        title: 'TTL CTN CBM',
                        table: 'total_ctn_cbm',
                        active: false
                    },
                    {
                        title: 'Remarks',
                        table: 'remarks',
                        active: true
                    },

                ],
                product:{},
                edit_product_dialog: false
            }
        },
        computed: {
            allowed_access_to_final: function () {
                return this.$store.getters.user_groups.filter(value => [1, 2, 15].includes(value));
            },
            get_title: function () {
                return (this.order) ? ((this.order.stage < 5) ? 'Quote: ' : 'Product Invoice: ') + this.order.pi_number : "Product Invoide: Edition";
            },
            get_stage: function () {
                return (this.order) ? this.order.stage : 1;
            },
            total_ctn: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (line['pieces_per_master_carton'] || 0), 0) : 0;
            },
            total_item_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_item_ttl(line) || 0), 0) : 0;
            },
            total_carton_cbm: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (this.calc_carton_ttl(line) || 0), 0) : 0;
            },
            client_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.organisation_fob_price) || 0), 0) : 0;
            },
            factory_total: function () {
                return (!!this.order) ? this.order.orderlines.reduce((a, line) => a + (parseInt(line.qty) * parseFloat(line.factory_fob_price) || 0), 0) : 0;
            }
        },
        created() {
            this.invoice_id = this.$route.params.invoice_id;
            this.load_order();
        },
        watch: {
            'order.organisation': function (id) {
                if(typeof id !== undefined && parseInt(id) > 0 && this.customers.length)
                {
                    this.customer = this.customers.find(o => o.id === id);
                }
            },
        },
        methods: {
            async download_generated_pi_or_quote(event, order){
                order.is_loading_pi = true;
                let worksheet_buffer = (order.stage > 5)? await gen_order_pi(this, this.order, this.order.orderlines) : await gen_order_quote(this, this.order, this.order.orderlines);
                await saveAs(new Blob([worksheet_buffer]), order.pi_number + ".xlsx");
                order.is_loading_pi = false;
            },

            edit_product(p){
                this.product = p;
                this.edit_product_dialog = true;
            },

            async save_product(p){
                let res = await this.$hpost('/logistics/edit-product/order/', {
                    id: p.id,
                    name: p.name,
                    description: p.description
                });

                if(res.status >= 200 || res.status <300){
                    this.$notify({
                        title: 'Success:',
                        text: 'Successfully updated product',
                        group: 'notifications',
                        position: 'top right'
                    });
                    this.product = {};
                    this.edit_product_dialog = false;
                }
            },

            check_access(tbl, acc) {
                if (['cust_fob', 'cust_target', 'cust_total'].includes(tbl))
                    return acc;
                return true;
            },

            rebase_prices(line, percent) {
                console.log(percent / 100);
                console.log(percent / 100 * line.factory_fob_price);
                console.log(parseFloat(line.factory_fob_price) + (percent / 100 * parseFloat(line.factory_fob_price)));
                console.log(line);
                let new_org_price = parseFloat(line.factory_fob_price) + (percent / 100 * parseFloat(line.factory_fob_price));

                if (confirm('Are you sure you want to rebase ' + line.product.item_number + ' ' +
                    'the price will be changed from: ' + line.organisation_fob_price + ' to: ' + new_org_price + ' ?')) {
                    line.organisation_fob_price = new_org_price;
                    this.$forceUpdate();
                    this.saveOrder()
                }
            },

            set_product_sel(product) {
                product.active = !(product.active && product.active === true);
                this.$forceUpdate();
            },

            search_products() {
                this.$hget('/store/search/?min_fob=0&max_fob=6999.3&item_number=' + this.search.item_number)
                    .then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.products = response.data.results;
                    }
                });
            },

            add_products_to_order() {
                let products = this.products.filter(o => o.active === true);

                this.$hpost('/logistics/add-product/order/', {
                    invoice_id: this.order.invoice_id,
                    pi_number: this.order.pi_number,
                    products: products
                }).then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.product_dialog = false;
                        this.search = {
                            item_number: ''
                        };
                        this.products = [];
                        this.load_order();
                    }
                });
            },

            async remove_ol(ol) {
                if (confirm('Are you sure you want to remove ' + ol.product.name + ' from this order ?')) {
                    let res = await this.$hpost('/logistics/delete/order/', { invoice_id: ol.invoice_id, id: ol.id});

                    if(res.status >= 200 || res < 300){
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully deleted product from order',
                            group: 'notifications',
                            position: 'top right'
                        });
                    }

                    this.load_order();

                }

            },

            async load_order() {
                this.is_loading = true;

                // Getting previous data
                this.partners = await this.$hget('/admins/partners').then(r => r.data) || [];
                this.customers = await this.$hget('/logistics/customers/').then(r => r.data) || [];

                console.log(this.customers)

                // Getting order
                let order = await this.$hget('order/' + this.invoice_id).then(response => response.data);
                if(!order.shipping_end_date)
                {
                    order.shipping_end_date = order.created_at;
                }
                if(!order.shipping_start_date)
                {
                    order.shipping_start_date = order.created_at;
                }
                order.shipping_end_date = formatDateForPickers(order.shipping_end_date);
                order.shipping_start_date = formatDateForPickers(order.shipping_start_date);
                this.order = order;
                // Getting order values as js objects
                this.customer = this.customers.find(o => o.id === this.order.organisation);

                if (typeof this.order.partner !== 'undefined')
                    this.order.partner = this.partners.find(o => o.id === this.order.partner);


                console.log("Order:", this.order);

                this.order_old = Object.assign(this.order, {});
                this.is_loading = false;
            },

            async save_order(){
                this.is_loading = true;
                await this.$hpost('/order/' + this.$route.params.invoice_id + '/', {order: this.order});

                this.load_order();

                this.$notify({
                    title: 'Success:',
                    message: 'successfully saved PI',
                    group: 'notifications',
                    position: 'top right'
                });

                this.is_loading = false;
            },

            async open_factory_mail(factory) {
                this.$modal.show(SendFactoryEmail,
                    {
                        factory: factory,
                        invoice_id: this.order.invoice_id
                    },
                    {
                        adaptive: true,
                        scrollable: true,
                        reset: true,
                        height: 'auto',
                        width: '80%',
                        clickToClose: true
                    }
                );
            },

            async clone_orders() {
                let confirm_text = 'This will make another PI with selected factory products and also keep them in this PI are you sure you want to continue?'

                if (confirm(confirm_text)) {

                    if (!this.factories_selected.length) {
                        this.$notify({
                            title: 'Error:',
                            text: 'No Factories Selected',
                            group: 'notifications',
                            position: 'top right'
                        });
                    } else {
                        let send_data = {
                            factories: this.factories_selected.map(f => f.id),
                            invoice_id: this.order.invoice_id
                        };
                        let new_pi_number = await this.$hpost('/admins/clone-pi/', send_data)
                            .then(r => r.data);

                        new_pi_number = new_pi_number.new_pi_number;
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully cloned Products under the new QT number: ' + new_pi_number,
                            group: 'notifications',
                            position: 'top right'
                        });

                        this.load_order();
                    }
                }
            },

            async move_orderlines() {
                if (!this.factories_selected.length) {
                    this.$notify({
                        title: 'Error:',
                        text: 'No Factories Selected',
                        group: 'notifications',
                        position: 'top right'
                    });
                } else if (this.factories_selected.length === this.order.factories.length) {
                    this.$notify({
                        title: 'Error:',
                        text: 'You can\'t move all factories',
                        group: 'notifications',
                        position: 'top right'
                    });
                } else {
                    let new_pi_number = await this
                        .$hpost('/admins/move-pi/', {
                            factories: this.factories_selected.map(f => f.id),
                            invoice_id: this.order.invoice_id
                        })
                        .then(r => r.data);
                    new_pi_number = new_pi_number.new_pi_number;
                    this.$notify({
                        title: 'Success:',
                        text: 'Successfully Moved Products under the new QT number: ' + new_pi_number,
                        group: 'notifications',
                        position: 'top right'
                    });
                    this.load_order();
                }
            },

            format_date_for_sql(date) {
                return moment(String(d)).format('YYYY-MM-DD')
            },

            add_remove_selected_factory(factory) {
                if (this.factories_selected.includes(factory))
                    this.factories_selected = this.factories_selected.filter(value => {
                        value.id !== factory.id
                    });
                else
                    this.factories_selected.push(factory);

                console.log(this.factories_selected)
            },

            get_factory_name(factory_id) {
                let factory = this.order.factories.find(f => f.id === factory_id);
                if(!factory)
                {
                    return 'N/A';
                }
                return '[' + factory.id + ']' + factory.name;
            },

            calc_item_cbm(line) {
                return calc_item_cbm(line);
            },
            calc_carton_cbm(line) {
                return calc_carton_cbm(line);
            },
            calc_item_ttl(line){
                return calc_item_ttl(line);
            },
            calc_carton_ttl(line){
                return calc_carton_ttl(line);
            },
            calc_total_master_ctn(line){
                return calc_total_master_ctn(line);
            },
            calc_factory_target_fob(line) {
                return calc_factory_target_fob(line);
            },
            calc_customer_target_fob(line) {
                return calc_customer_target_fob(line);
            },
            calc_line_department_increase(line){
                return calc_line_department_increase(line);
            },
            calc_line_department_increase_success(line){
                return calc_line_department_increase_success(line);
            },
            customer_target_price_percentage_difference(line){
                return customer_target_price_percentage_difference(line);
            },
            set_to_fixed(number, to_pos)
            {
                number = parseInt(number);

                if(typeof number === undefined || !number)
                {
                    number = 0;
                }

                return number.toFixed(to_pos);
            }

        }


    }
</script>

<style lang="scss">
    .search_product.active {
        border: 2px solid green;
    }

    .invoice_items_table {
        table {
            /*min-width: 2500px;*/
        }
    }

    $stages-base_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;
    $stages-highlight_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;

    @for $i from 1 through 13 {
        .stage_btn-#{$i} {
            border: 2px solid nth($stages-base_color, $i);
        }
    }

    .order-chat-wrapper {
        width: 400px;
        height: 500px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 900;
        background: white;

        .close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 901;
        }
    }


</style>
